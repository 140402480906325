import React from 'react'
// import ScreenIcon from './Icon/ScreenIcon'
import { graphql, useStaticQuery } from "gatsby";

export default function EventPageHeading(props) {

	const { festivalType, festivalYear, title } = props

	const meta = useStaticQuery(graphql`{
		site {
			siteMetadata {
				title
				config {
					boxOfficeEnabled
					envFestivalYear: festivalYear
				}
			}
		}
	}`)

	const { boxOfficeEnabled, envFestivalYear } = meta.site.siteMetadata.config

	const getContent = () => {
		if ( !boxOfficeEnabled || ( boxOfficeEnabled && festivalYear !== envFestivalYear )) {
			return null
		}

		let typeSpecificContent = `Main festival events will be live at Dumfries House with the exception of Rory Stewart and Esther Rutter on Friday. Tickets range from £10 to £12.`

		switch (festivalType) {
			case 'childrens':
				typeSpecificContent = `Children’s festival events are live at the weekend and cost £3, or £2 for KA18 postcode addresses.`
				break
			case 'schools':
				typeSpecificContent = `Schools events are free and available from 12 May to all. Registration required.`
				break
			default:
				break
		}

		return (
			<>
				{/*<p className={`text-xl text-bbf-red mb-4`}>Until Thursday 23 March, tickets are only available to members of our Friends and Patrons scheme.</p>*/}
				<p className={`text-xl mb-4`}>{ typeSpecificContent }</p>
				{ festivalType === 'main' && (
					<>
						<p className={`text-lg mb-4`}>Events in the main venues will also be live-streamed.</p>
						{/*<p>Look out for the screen icon <ScreenIcon className={`w-5`}/>.</p>*/}
						<p>Online tickets are £5 per event or £35 for a Rover Pass giving access to all online events.</p>
						<p>You will receive the links needed to access online events via the e-ticket that will be emailed to you.</p>
					</>
				)}
			</>
		)
	}

	return (
		<div className={`mb-12`}>
			<h2 className={`text-3xl mb-4`}>{title}</h2>
			{ getContent() }
		</div>
	)

}
