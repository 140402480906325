import React from 'react'
import FTEventList from '../FTEvent/FTEventList'
import EventPageHeading from '../EventsPageHeading'

export default function EventsPage(props) {
	return (
		<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
			<div className={`p-4 md:p-12 w-full`}>
				<EventPageHeading
					festivalType={props.festivalType}
					festivalYear={props.festivalYear}
					title={props.pageHeading}
				/>
				<FTEventList
					data={props.eventsData}
					festivalType={props.festivalType}
					festivalYear={props.festivalYear}
					showAllEventsOption={props.showAllEventsOption}
					showDayFilter={props.showDayFilter}
					showEventDate={props.showEventDate}
					showTypeFilter={props.showTypeFilter}
					useLinksForTypeFilter={props.useLinksForTypeFilter}
				/>
			</div>
		</section>
	)
}
