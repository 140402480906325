import React from 'react'
import { getFormattedDisplayTime } from '../../utils/dates'

const EventDayOverlay = (props) => {
	const { date } = props
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	const day = new Date(date).getDay()

	const time = getFormattedDisplayTime(date)

	return (
		<div className={`absolute bottom-0 left-0 w-12 h-12 p-1 bg-white`}>
			<p className={`text-sm text-center font-bold`}>{days[day]}</p>
			<p className={`text-xs text-center`}>{time}</p>
		</div>
	)
}

export default EventDayOverlay
