import React, { useState } from 'react'
import EventDayFilter from '../Event/EventDayFilter'
import EventTypeFilter from '../Event/EventTypeFilter'
import { getFestivalDatesByType, getDefaultDisplayDate } from '../../utils/dates'
import FTEventListItem from './FTEventListItem'

const FTEventList = (props) => {

	const { data, festivalType, showDayFilter, showTypeFilter, showAllEventsOption, showEventDate, useLinksForTypeFilter } = props
	const festivalYear = props.festivalYear ? props.festivalYear : 2022

	const getEventsByType = (type) => {
		return data[type]
	}

	const filterEventsByDate = (filterDate, type) => {
		return getEventsByType(type).filter(item => {
			return item.date === filterDate
		})
	}

	const getInitialEvents = () => {
		if (!showDayFilter && !showTypeFilter && showAllEventsOption) {
			return getEventsByType(festivalType)
		}
		return filterEventsByDate(getDefaultDisplayDate(festivalType, festivalYear), festivalType)
	}

	// set up the component state
	const [ eventType, setEventType ] = useState(festivalType)
	const [ displayDate, setDisplayDate ] = useState(getDefaultDisplayDate(festivalType, festivalYear))
	const [ events, setEvents ] = useState(getInitialEvents())

	const handleEventDayChange = (newDate) => {
		if (newDate !== displayDate) {
			setDisplayDate(newDate)

			if (newDate === 'all') {
				setEvents(getEventsByType(eventType))
				return
			}

			setEvents(filterEventsByDate(newDate, eventType))
		}
	}

	const handleEventTypeChange = (newType) => {
		if (useLinksForTypeFilter) {
			return
		}
		if (eventType !== newType) {
			setEventType(newType)
			setDisplayDate(getDefaultDisplayDate(newType, festivalYear))
			setEvents(filterEventsByDate(getDefaultDisplayDate(newType, festivalYear), newType))
		}
	}

	return (
		<>
			{ showTypeFilter && (
				<EventTypeFilter
					changeHandler={handleEventTypeChange}
					festivalYear={festivalYear}
					type={eventType}
					useLinks={useLinksForTypeFilter}
				/>
			) }
			{ showDayFilter && (
				<EventDayFilter
					changeHandler={handleEventDayChange}
					festivalYear={festivalYear}
					getDates={getFestivalDatesByType}
					selectedDate={displayDate}
					showAllEventsOption={showAllEventsOption}
					type={eventType}
				/>
			) }
			<div className={`mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5`}>
				{events.map((item, index) => {
					return <FTEventListItem data={item} key={index} eventType={eventType} showEventDate={showEventDate} />
				})}
			</div>
		</>
	)
}

export default FTEventList
