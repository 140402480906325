import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import EventDayOverlay from '../Event/EventDayOverlay'

export default function EventItem(props) {
	const { data, eventType, showEventDate } = props
	const eventYear = data.date.split('-')[0]

	// the valid key from the FT api is a string representing 0 or 1 <rolls-eyes>
	if (data.valid === "1") {
		return (
			<div className={`shadow-md bg-white flex flex-col items-center`}>
				<Link to={`/${eventYear}/events/${data.ft_id}`} className={`block w-full`}>
					<div className={`w-full relative`}>
						<GatsbyImage
							image={data.local_event_img.childImageSharp.gatsbyImageData}
							alt={data.name}
							objectFit={`contain`} />
						{ eventType !== 'schools' && showEventDate && <EventDayOverlay date={data.datetime} /> }
					</div>
					<div className={`p-2`}>
						<h2 className={`mb-2 text-lg font-heading leading-tight text-bbf-dark-green pb-1 border-b-2 border-bbf-mid-green-alt`}>{data.name}</h2>
						<div className={`text-sm line-clamp-4 max-h-20 overflow-hidden`} dangerouslySetInnerHTML={{__html: data.description}} />
					</div>
				</Link>
			</div>
		)
	} else {
		return null
	}
}
